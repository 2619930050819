<template>
  <div>
    <base-form
      :formAttrs="{
        model: queryParas,
      }"
      :componentList="componentList"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      class="form"
    ></base-form>
    <div class="main-page-table__header">
      <base-button label="人员调整" icon="iconfont iconrenyuantiaozheng" @click.prevent="handleAdduser"></base-button
      ><base-button label="删除" icon="iconfont iconshanchu1" @click="delBtn"></base-button>
    </div>
    <base-table
      :webPage="false"
      :queryParas.sync="queryParas"
      :api="api"
      getApi="accountPage"
      ref="orgTable"
      :loadCount="loadCount"
      :dataSource.sync="tableData"
      @selection-change="handleSelectionChange"
      :columns="columns"
      class="main-page-table with-add"
    >
      <template slot="leaderFlag" slot-scope="{ row }">
        <el-switch
          v-model="row.leaderFlag"
          :active-color="'#6996FF'"
          :inactive-color="'#dcdfe6'"
          active-value="1"
          inactive-value="0"
          @change="handleLeaderChange(row)"
        >
        </el-switch>
      </template>
    </base-table>
    <base-dialog :visible.sync="visible" :showFooter="false" title="人员调整">
      <div class="transfer">
        <el-transfer
          :props="defaultProps"
          filterable
          :filter-method="filterMethod"
          filter-placeholder="请输入搜索内容"
          v-model="value"
          :data="sourceData"
          @change="change"
          :titles="['所有人员', '当前人员']"
        >
        </el-transfer>
      </div>
      <template slot="footer">
        <base-button
          label="保 存"
          @click="onSaved"
          :loading="loading"
          :disabled="disabled"
        ></base-button>
        <base-button
          label="关 闭"
          type="default"
          @click="visible = false"
        ></base-button>
      </template>
    </base-dialog>
    <assign-org
      ref="assignOrg"
      :orgId="currentOrgId"
      @refresh="handleFilter"
    ></assign-org>
  </div>
</template>

<script>
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseForm from '@/components/common/base-form//base-form.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { orgApi } from '@/api/orgApi'
import assignOrg from './assign-org.vue'
// import { groupManager, PLATFORM_MANAGER, sysManager } from '@/config'
// import { validateEmojiReg } from '@/pages/my-financing-bill/main.config'
export default {
  components: {
    BaseForm,
    BaseButton,
    BaseTable,
    assignOrg,
    BaseDialog
    // PersonEdit: () => import('./person-edit.vue'),
    // userEdit: () => import('../components/user-edit.vue')
  },
  computed: {
    api () {
      return orgApi
    },
    roleCode () {
      return this.$store.getters.roleInfo.roleCode || ''
    },
    showActions () {
      // if (
      //   // 系统管理员
      //   (this.roleCode === sysManager && this.currentOrgType === 4) ||
      //   // 集团管理员
      //   (this.roleCode === groupManager && this.currentOrgType === 1) ||
      //   // 核心企业
      //   (this.roleCode === PLATFORM_MANAGER &&
      //     (this.currentOrgType === 2 || this.currentOrgType === 3))
      //   // 区域 子公司
      //   // (this.roleCode === PLATFORM_MANAGER && data.companyType === 1)
      // ) {
      //   return true
      // }
      return true
    }
  },
  data () {
    return {
      tableData: [],
      sourceData: [],
      // 原始数据，判断是否移动人员
      originalValue: [],
      value: [],
      valueList: [],
      defaultProps: {
        key: 'accountId',
        label: 'nickName'
      },
      disabled: true,
      loading: false,
      batchArrayList: [],
      filterMethod (query, item) {
        const str = query.trim()
        return item.nickName.indexOf(str) > -1
      },
      saveForm: {
        accountId: [],
        list: [],
        orgId: '',
        posId: ''
      },
      visible: false,
      currentOrgType: '',
      queryParas: {},
      tableDatas: [],
      loadCount: 0,
      currentOrgName: '',
      currentOrgId: '',
      componentList: [
        {
          label: '登录账号',
          prop: 'account',
          attrs: {
            maxlength: 25,
            placeholder: '请输入'
          },
          rules: [{ validator: '', trigger: ['blur', 'change'] }]
        },
        {
          label: '姓名',
          prop: 'nickName',
          attrs: {
            maxlength: 20,
            placeholder: '请输入'
          },
          rules: [{ validator: '', trigger: ['blur', 'change'] }]
        }
      ],
      columns: [
        {
          type: 'selection',
          width: '50px'
        },
        {
          prop: 'orgName',
          label: '组织机构名称',
          minWidth: '100px'
        },
        {
          prop: 'leaderFlag',
          label: '部门负责人',
          minWidth: '100px'
        },
        {
          prop: 'account',
          label: '登录账号',
          minWidth: '100px'
        },
        {
          prop: 'nickName',
          label: '人员姓名',
          minWidth: '100px'
        }
      ]
    }
  },
  activated () {
    this.$nextTick(() => {
      this.$refs.orgTable.doLayout()
    })
  },
  methods: {
    /* 判断是否有 部分负责人 */
    checkExitLeader ({ accountId }) {
      return this.tableData.some(it => {
        return it.accountId !== accountId && it.leaderFlag === '1'
      })
    },

    async handleLeaderChange (row) {
      // 0 -> 1 开启
      // 1 -> 0 取消
      const { leaderFlag } = row
      if (leaderFlag === '1' && this.checkExitLeader(row)) {
        row.leaderFlag = leaderFlag === '1' ? '0' : '1'
        return this.warning('该机构已存在部门负责人')
      }
      try {
        // 取消授权 做用户认证
        if (leaderFlag === '0') {
          await this.$confirm('取消此用户部门负责人权限?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            closeOnClickModal: false,
            closeOnPressEscape: false
          })
        }

        // 后台修改数据
        await orgApi.settingLeader({
          accountId: row.accountId,
          leaderFlag,
          orgId: row.orgId
        })

        // 消息提示
        this.success(
          leaderFlag === '1' ? '设置部门负责人成功' : '取消部门负责人成功'
        )
      } catch (error) {
        // 授权失败或取消时 状态回退
        row.leaderFlag = leaderFlag === '1' ? '0' : '1'
      }
    },

    handleAssginUser () {
      this.$refs.personEdit.open()
    },

    edit (row) {
      this.$refs.userEdit.open('E', row)
    },

    // 删除成员
    delBtn () {
      if (this.batchArrayList.length === 0) {
        return this.$message.warning('请先勾选要删除的项')
      }
      const $this = this
      const arr = this.batchArrayList.map((it) => {
        return it.accountId
      })
      $this
        .$confirm('确认删除选中的人员?', '提示', {
          type: 'warning',
          closeOnClickModal: false,
          closeOnPressEscape: false
        })
        .then(() => {
          $this.api
            .dels({ orgId: this.currentOrgId, accountIds: arr })
            .then((res) => {
              if (res && res.data) {
                $this.success('删除成功')
                $this.handleFilter()
                $this.$eventBus.$emit('msgChangeSuccess')
              } else {
                $this.error('删除失败')
              }
            })
        })
        .catch(() => {})
    },

    equalStr (val1, val2) {
      val1.sort()
      val2.sort()
      return JSON.stringify(val1) === JSON.stringify(val2)
    },

    change () {
      this.disabled = this.equalStr(this.originalValue, this.value)
    },

    handleFilter () {
      this.queryParas.pageIndex = 1
      this.queryParas.orgId = this.currentOrgId
      this.loadCount++
    },

    clearParams () {
      const orgId = this.queryParas.orgId
      this.queryParas = {
        orgId
      }
    },

    refresh (item) {
      this.currentOrgType = item.companyType
      this.currentOrgId = item.keyId
      this.currentOrgName = item.companyName
      this.clearParams()
      this.handleFilter()
    },

    handleAdduser () {
      // 更换人员调整代码
      this.$refs.assignOrg.open()
      // this.loadUserByTenant()
      // this.loadOrgPerson()
      // this.visible = true
    },

    // 加载当前组织机构下的所有人员
    loadOrgPerson () {
      const $this = this
      $this.value = []
      $this.valueList = []
      $this.sourceData = []
      $this.api.accountList({ orgId: $this.currentOrgId }).then((res) => {
        if (res && res.data) {
          $this.sourceData = res.data
          res.data.forEach((it) => {
            $this.value.push(it.accountId)
            $this.valueList.push({
              accountId: it.accountId,
              leaderFlag: it.leaderFlag
            })
          })
          $this.originalValue = JSON.parse(JSON.stringify($this.value))
          $this.sourceData.forEach((item) => {
            if (item.mobile) {
              item.nickName =
                item.nickName +
                '-' +
                item.mobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
            }
          })
        }
      })
    },
    // 加载当前租户下所有用户列表
    loadUserByTenant () {
      const $this = this
      this.sourceData = []
      this.api.autherList({ orgId: '' }).then((res) => {
        if (res && res.data) {
          res.data.forEach((it) => {
            const p = {
              account: '',
              accountId: '',
              nickName: '',
              mobile: ''
            }
            if (it.mobile) {
              it.nickName =
                it.nickName +
                '-' +
                it.mobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
            }
            p.mobile = it.mobile
            p.account = it.userName
            p.accountId = it.userId
            p.nickName = it.nickName
            $this.sourceData.push(p)
          })
        }
      })
    },
    // 复选框设置
    handleSelectionChange (arrList) {
      this.batchArrayList = arrList
    },
    // 保存
    onSaved () {
      const $this = this
      $this.saveForm.accountId = $this.value
      $this.saveForm.orgId = $this.currentOrgId
      for (let i = 0; i < $this.value.length; i++) {
        for (let j = 0; j < $this.sourceData.length; j++) {
          if ($this.value[i] === $this.sourceData[j].accountId) {
            const p = {
              account: '',
              accountId: '',
              nickName: '',
              mobile: '',
              leaderFlag: '0'
            }
            var a = $this.valueList.filter(
              (item) => $this.value[i] === item.accountId
            )
            if (a.length > 0) {
              if (a[0].leaderFlag === '') {
                p.leaderFlag = '0'
              } else {
                p.leaderFlag = a[0].leaderFlag
              }
            }
            const arr = $this.sourceData[j].nickName.split('-')
            p.account = $this.sourceData[j].account
            p.accountId = $this.sourceData[j].accountId
            p.nickName = arr[0]
            p.mobile = $this.sourceData[j].mobile
            $this.saveForm.list.push(p)
          }
        }
      }
      $this.loading = true
      $this.api
        .adjustPerson($this.saveForm)
        .then((res) => {
          if (res && res.data) {
            $this.success('人员调整成功')
            $this.loading = false
            $this.visible = false
            $this.handleFilter()
          }
        })
        .catch(() => {
          $this.loading = false
        })
    }
  }
}
</script>

<style lang='scss' scoped>
.form {
  background: #fff;
  margin: 0 !important;
}

.transfer {
  width: 100%;
  height: 100%;
  /deep/ .el-transfer {
    width: 100%;
    height: 100%;
    text-align: center;
  }
  /deep/ .el-transfer-panel {
    width: 33%;
    height: 100%;
    text-align: left;
    .el-transfer-panel__body {
      height: 100%;
      .el-transfer-panel__filter {
        width: 90%;
        margin: 5 auto;
      }
      .el-transfer-panel__list.is-filterable {
        height: 100%;
      }
    }
  }
}
.pd10 {
  padding: 10px;
}
</style>
