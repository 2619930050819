import { checkRoleExist } from '@/api/sys/role'
import BaseSelect from '@/components/common/base-select/base-select.vue'
import { getDictLists } from '@/filters/fromDict.js'

// 增加form
export const addFormConfig = (context) => {
  const isEditor = context.model === 'E'
  const span = 8
  return [
    {
      label: '机构类型:',
      prop: 'orgType',
      attrs: {
        options: getDictLists('USER_TYPE'),
        selectedField: ['dictId', 'dictName']
      },
      rules: [{ required: true, message: '请选择机构类型', trigger: ['blur', 'change'] }],
      span,
      tag: BaseSelect
    },
    {
      label: '角色名:',
      prop: 'roleName',
      rules: [{ required: true, message: '请输入角色名称', trigger: 'blur' },
        { validator: validateRoleName, trigger: ['blur', 'change'] }],
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      },
      span
    },
    {
      label: '角色编码:',
      prop: 'roleCode',
      rules: [
        {
          required: true,
          message: '请输入角色编码',
          trigger: 'blur'
        },
        {
          min: 3,
          max: 20,
          message: '角色编码长度需在3-20之间',
          trigger: ['blur', 'change']
        },
        { validator: validateRoleCode(context, isEditor), trigger: 'blur' }
      ],

      attrs: {
        placeholder: '请输入',
        maxlength: 20
      },
      span
    },
    {
      label: '角色描述：',
      prop: 'roleDesc',
      span: 24,
      attrs: {
        placeholder: '请输入',
        type: 'textarea',
        maxlength: '200',
        rows: 6
      }
    }
  ]
}
/**
 * 角色名称输入校验
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */
const validateRoleName = (rule, value, callback) => {
  // 数字、字母、下划线、括号（中英文）
  const reg = /^([\u4e00-\u9fa5]|\w|\(|\)|（|）)+$/
  if (value && !reg.test(value)) {
    callback(
      new Error('只能输入中英文、数字、下划线和括号')
    )
  } else {
    callback()
  }
}
/**
 * 校验角色是否存在
 * @param {} rule
 * @param {*} value
 * @param {*} callback
 */
export const validateRoleCode = (context, isEditor) => {
  const {
    preData: { roleCode, roleId }
  } = context
  return (rule, value, callback) => {
    const postData = {
      roleCode: value
    }
    isEditor && (postData.roleId = roleId)
    const reg = /^[a-zA-Z]{1}(([a-zA-Z0-9]|[_])?){3,20}$/
    if (reg.test(value)) {
      if (roleCode === value) {
        callback()
      } else {
        checkRoleExist(postData)
          .then((res) => {
            if (res.data) {
              callback(new Error('该角色已存在'))
            } else {
              callback()
            }
          })
          .catch(() => {
            callback(new Error('角色判定失败'))
          })
      }
    } else {
      callback(
        new Error('角色编码以字母开头且只能输入字母、数字和下划线')
      )
    }
  }
}
/**
 * 过滤重复数据
 * @param {*} data
 * @param {*} key
 * @returns
 */
export const filterByKey = (data, key) => {
  const res = []
  const resMap = Object.create(null)
  data.forEach((it) => {
    const item = it[key] // id
    if (!resMap[item]) {
      resMap[item] = 1
      res.push(it)
    }
  })
  return res
}
