import http from '@/utils/http'
import Rest from '@/utils/http/Rest'

export default class Risk extends Rest {
  /**
   * 获取当前租户下所有（整棵）组织结构树
   * @param {*} params 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  tenantlist (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/tenant/list`,
      params
    })
  }

  /**
   * 新增机构
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  insertorg (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: `/api/${this.subSys}/${this.module}`,
      data
    })
  }

  /**
   * 修改机构
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  orgUpdate (data) {
    return http({
      showLoading: true,
      method: 'put',
      url: `/api/${this.subSys}/${this.module}/${data.keyId}`,
      data
    })
  }

  /**
   * 删除机构
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  delOrg (params) {
    return http({
      showLoading: true,
      method: 'delete',
      url: `/api/${this.subSys}/${this.module}/batch`,
      params
    })
  }

  /**
   * 根据组织ID查询当前机构及登录人员所属机构的人员列表
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  accountPage (params) {
    return http({
      showLoading: true,
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/account/page`,
      params
    })
  }

  /**
   * 查询当前租户下所有用户列表
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  autherList (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/auther/account/list`,
      params
    })
  }

  /**
   * 查询机构人员列表
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  accountList (params) {
    return http({
      method: 'get',
      url: `/api/${this.subSys}/${this.module}/account/list`,
      params
    })
  }

  /**
   * 查询机构人员列表
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  dels (params) {
    return http({
      showLoading: true,
      method: 'delete',
      url: `/api/${this.subSys}/${this.module}/account/batch`,
      params
    })
  }

  /**
   * 机构人员调整
   * @param {*} data 请求参数
   * @returns {*|AxiosPromise} 响应
   */
  adjustPerson (data) {
    return http({
      showLoading: true,
      method: 'put',
      url: `/api/${this.subSys}/${this.module}/account`,
      data
    })
  }

  /**
   * 设置部门负责人
   *
   * @param {*} data
   * @returns
   */
  settingLeader (data) {
    return http({
      showLoading: true,
      method: 'post',
      url: '/api/authcenter/org/account/leader',
      data
    })
  }
}
