<template>
  <base-dialog :visible.sync="visible" :showFooter="false" title="人员调整">
    <div class="transfer">
      <el-transfer
        ref="Transfer"
        :props="defaultProps"
        filterable
        :filter-method="filterMethod"
        filter-placeholder="请输入搜索内容"
        v-model="checkedUsers"
        :data="totalRoles"
        @change="change"
        :titles="['所有人员', '当前人员']"
      >
      </el-transfer>
    </div>
    <template slot="footer">
      <base-button
        label="保 存"
        @click="onSaved"
        :loading="loading"
        :disabled="disabled"
      ></base-button>
      <base-button
        label="关 闭"
        type="default"
        @click="visible = false"
      ></base-button>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { orgApi } from '@/api/orgApi'
import { EmojiReg } from '@/config/reg-config'
import { debounce } from '@/utils/dom-util'
import { filterByKey } from '../../role-management/utils/edit-config'

const formatUserData = (data) => {
  return data.map((it) => {
    return {
      account: it.userName,
      accountId: it.userId,
      nickName: filterNameByPhone(it),
      mobile: it.mobile,
      pnickName: it.nickName
    }
  })
}
/**
 * 昵称
 */
const filterNameByPhone = (it) => {
  const { nickName, mobile } = it
  return mobile
    ? it.nickName + '-' + it.mobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
    : nickName
}

export default {
  components: { BaseButton, BaseDialog },
  props: {
    orgId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      visible: false,
      loading: false,
      disabled: true,
      sourceData: [],
      filterMethod: null,
      defaultProps: {
        key: 'accountId',
        label: 'nickName'
      },
      beforeChecks: [],
      watcher: null,
      checkedUsers: [],
      allUsers: [],
      initCheckeds: [],
      selectedRoles: []
    }
  },
  computed: {
    totalRoles () {
      return filterByKey(
        [...this.selectedRoles, ...this.allUsers, ...this.initCheckeds],
        'accountId'
      )
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.$nextTick(() => {
          // 穿梭框搜索框添加输入位数限制
          console.log(EmojiReg)
          const dom = document.getElementsByClassName('el-transfer-panel__filter')
          dom[0].childNodes[1].setAttribute('maxlength', 50)

          dom[0].childNodes[1].addEventListener('keypress', function (e) {
            if (e.keyCode === 32) {
              e.returnValue = false
            }
          }, true)
          dom[0].childNodes[1].addEventListener('input', function (e) {
            e.target.value = e.target.value.replace(EmojiReg, '')
          }, true)
          dom[1].childNodes[1].setAttribute('maxlength', 50)
          dom[1].childNodes[1].addEventListener('keypress', function (e) {
            if (e.keyCode === 32) {
              e.returnValue = false
            }
          }, true)
          dom[1].childNodes[1].addEventListener('input', function (e) {
            e.target.value = e.target.value.replace(EmojiReg, '')
          }, true)
        })
      }
    }
  },
  methods: {
    open () {
      this.visible = true
      this.getInitData()
      this.registerChildWatch()
      this.registerClear()
    },
    async getInitData () {
      this.checkedUsers = []
      this.allUsers = []
      this.initCheckeds = []
      this.selectedRoles = []

      const [{ data: users }, { data: checkUsers = [] }] = await Promise.all([
        //  获取所有用户
        orgApi.autherList({ orgId: '' }),
        // 获取当前角色下的用户列表
        orgApi.accountList({ orgId: this.orgId })
      ])

      // 选择的用户
      this.checkedUsers = checkUsers.map((it) => it.accountId)
      // 保存初始选中数据
      this.beforeChecks = [...this.checkedUsers]
      // 将数据结构转成右边的结构  便于保存
      this.allUsers = formatUserData(users)
      // 初始选中的数据进行格式化
      this.initCheckeds = checkUsers.map((it) => ({
        ...it,
        pnickName: it.nickName,
        nickName: filterNameByPhone(it)
      }))
    },

    registerChildWatch () {
      const tick = debounce(this.getAllRole, 200)
      this.$nextTick(() => {
        this.watcher = this.$refs.Transfer.$refs.leftPanel.$watch(
          'query',
          (val) => {
            tick(val)
          }
        )
      })
    },
    /**
     * 关闭时的事件销毁
     */
    registerClear () {
      const listen = this.$watch('visible', function watcher () {
        listen()
        if (typeof this.watcher === 'function') {
          this.watcher()
        }
        this.allUsers = []
        this.disabled = true
        this.checkedUsers = []
        this.initCheckeds = []
        this.beforeChecks = []
        this.selectedRoles = []
        // 清楚搜索内容
        this.$refs.Transfer.clearQuery('left')
        this.$refs.Transfer.clearQuery('right')
      })
    },
    // 获取所有用户
    async getAllRole (val) {
      const { data: users } = await orgApi.autherList({
        orgId: '',
        search: val
      })
      this.allUsers = formatUserData(users)
    },
    // 保存
    async onSaved () {
      const list = []
      this.totalRoles.forEach(it => {
        const { accountId, account, pnickName, mobile, leaderFlag } = it
        if (this.checkedUsers.includes(accountId)) {
          list.push({
            accountId,
            account,
            nickName: pnickName,
            mobile,
            leaderFlag: leaderFlag || '0'
          })
        }
      })

      const postData = {
        list,
        accountId: this.checkedUsers,
        orgId: this.orgId
      }
      this.loading = true

      orgApi.adjustPerson(postData).then((res) => {
        this.loading = false
        if (res && res.data) {
          this.success('人员调整成功')
          this.visible = false
          this.$emit('refresh')
        } else {
          this.error('人员调整失败')
        }
      }).catch(() => {
        this.loading = false
      })
    },

    equalStr (val1, val2) {
      val1.sort()
      val2.sort()
      return JSON.stringify(val1) === JSON.stringify(val2)
    },

    change () {
      this.disabled = this.equalStr(this.beforeChecks, this.checkedUsers)
    }
  }
}
</script>

<style lang="scss" scoped>
.transfer {
  width: 100%;
  height: 100%;
  /deep/ .el-transfer {
    width: 100%;
    height: 100%;
    text-align: center;
  }
  /deep/ .el-transfer-panel {
    width: 33%;
    height: 100%;
    text-align: left;
    .el-transfer-panel__body {
      height: calc(100% - 40px);
      .el-transfer-panel__filter {
        width: 90%;
        margin: 5% auto;
      }
      .el-transfer-panel__list.is-filterable {
        height: calc(100% - 60px);
      }
    }
  }
}
</style>
