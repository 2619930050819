export const debounce = (func, wait = 0) => {
  let timeout = null
  let args
  function debounced (...arg) {
    args = arg
    if (timeout) {
      // 如果有 取消定时器
      clearTimeout(timeout)
      timeout = null
    }
    return new Promise((resolve, reject) => {
      timeout = setTimeout(async () => {
        try {
          const result = await func.apply(this, args)
          resolve(result)
        } catch (error) {
          reject(error)
        }
      }, wait)
    })
  }
  function cancel () {
    clearTimeout(timeout)
    timeout = null
  }

  function flush () {
    cancel()
    return func.apply(this, args)
  }
  debounced.cancel = cancel
  debounced.flush = flush
  return debounced
}
